<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
      <!-- @click="ActionSetDialogCampaign(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <select-request
            :v-model="params.affiliate"
            outlined
            label="Nome do afiliado"
            endpoint="/redirect?app=AFFILIATE&path=/api/get/affiliate"
            option-label="name"
            option-value="id"
            @update="(value) => (params.affiliate = value === null ? null : value)"
            clearable
          />
        </div>
        <div class="col-6">
          <q-input v-model="params.affiliateId" outlined label="ID do afiliado" type="text" clearable />
        </div>
        <div class="col-6">
          <q-select
            v-model="params.invoiceStatus"
            label="Status da fatura"
            outlined
            emit-value
            map-options
            :options="params.optionsInvoiceStatus"
            option-label="status"
            option-value="id"
            options-cover
            clearable
          />
        </div>

        <div class="col-6">
          <date-input
            buttons
            outlined
            label="Data de pagamento"
            :v-model="params.paymentDate"
            @update="(value) => (params.paymentDate = value)"
            clearable
            range
          />
        </div>

        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterCampaigns" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderApproveInvoices',
  mixins: [FinancialMixin],
  components: { SelectRequest, DateInput },
  data() {
    return {
      filter: true,
      params: {
        affiliate: null,
        affiliateId: null,
        invoiceStatus: 12,
        paymentDate: null,
        optionsInvoiceStatus: [
          { id: 4, status: 'Agendado' },
          { id: 12, status: 'Pré-aprovado' },
          { id: 5, status: 'Pago' },
          { id: 11, status: 'Recusado' }
        ]
      }
    };
  },
  methods: {
    clearFilter() {
      this.params = {
        affiliate: null,
        affiliateId: null,
        invoiceStatus: null,
        paymentDate: null,
        optionsInvoiceStatus: [
          { id: 4, status: 'Agendado' },
          { id: 12, status: 'Pré-aprovado' },
          { id: 5, status: 'Pago' },
          { id: 11, status: 'Recusado' }
        ]
      };
      this.ActionSetFilterInvoicesPaymentsStep('states=[12,4,5,11]');
      this.getInvoicesPaymentsStep();
    },

    onFilterCampaigns() {
      //page=1&per_page=10&order_by=trademark%7Casc&states=%5B12%5D
      let filterSelect = [];

      if (this.params.invoiceStatus) filterSelect.push('states=' + this.params.invoiceStatus);
      else filterSelect.push('states=[12,4,5,11]');

      if (this.params.paymentDate && !this.params.paymentDate.includes('Não Informado')) {
        let [from, , to] = this.params.paymentDate.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`payment_day={"start":"${from}","end":"${to}"}`);
      }

      if (this.params.affiliate) filterSelect.push('affiliateId=' + this.params.affiliate.id);
      if (this.params.affiliateId) filterSelect.push('affiliateId=' + this.params.affiliateId);
      // Needs to change de '&' to %26 to work
      this.ActionSetFilterInvoicesPaymentsStep(filterSelect.join('%26'));
      this.getInvoicesPaymentsAffiliate();
    }
  }
};
</script>
