<template>
  <q-layout>
    <q-page padding>
      <q-toolbar class="column">
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Aprovar Faturas</q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el label="Painel de Controle" to="/ " />
          <q-breadcrumbs-el label="Aprovar Faturas" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />

      <header-approve-invoices></header-approve-invoices>
      <list-approve-invoices></list-approve-invoices>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderApproveInvoices from '../../components/financial/HeaderApproveInvoices.vue';
import ListApproveInvoices from '../../components/financial/ListApproveInvoices.vue';

export default {
  name: 'ApproveInvoices',
  components: { HeaderApproveInvoices, ListApproveInvoices }
};
</script>
